import React from "react"
import { Link } from "gatsby"
import { motion } from 'framer-motion'
import {StaticImage} from "gatsby-plugin-image"
import Layout from "../components/layout"
import Cta from "../components/cta"
import Seo from "../components/seo"
import ClientLogos from "../components/client-logos"
import TestimonialSection from "../components/testimonials-section"
import ArrowRight from "../components/svg/arrowRight"
import Collapsible from 'react-collapsible';
import ChevronDown from "../components/svg/chevronDown"

const StartupWebDevPage = () => (
    <Layout>
        <Seo 
            title="Startup web app developers | startup software development" 
            description="Launch Lab are highly experienced onshore Australian startup web app developers. Build your startup's product, website or app MVP with confidence."
            pathname="/startup-web-developers/"
            serviceType="Startup web application development"
        />

        <section role="main">
            <div className="container">
            <div className="row flex flex-jc">
                <div className="hero-content">
                <h1
                    className="small-h"
                >Experienced Australian Startup Developers</h1>
                <motion.h2 
                    className="hero-lead"
                    initial="hidden"
                    animate={{ opacity: [0, 0.25, 0.5, 0.75, 1], y: [80, 0] }} 
                    transition={{ ease: "easeOut", duration: 0.5 }}
                >
                    Design and develop your startup product with confidence, onshore in Australia
                </motion.h2>
                <div className="btn-row">
                    <motion.div 
                    initial="hidden"
                    animate={{ opacity: [0, 0.25, 0.5, 0.75, 1], x: [-80, 0] }} 
                    transition={{ ease: "easeOut", duration: 0.75 }}
                    >
                    <Link 
                        to="/contact/" className="btn btn-lg btn-purple"
                    >
                        <span className="flex flex-ac">
                        Start your project
                        <ArrowRight />
                        </span>
                    </Link>
                    </motion.div>
                </div>
                </div>
            </div>
            </div>
        </section>

        <section className="bg-purple with-pad-sm">
            <div className="container">
                <div className="row">
                    <motion.div 
                      className="below-hero"
                      initial={{ opacity: 0, x: -120 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      viewport={{ once: true }}
                      transition={{ ease: "easeOut", delay: 0.25, duration: 0.75 }}
                    >
                        <div className="below-hero-widget">
                            <span className="widget-heading">40+</span>
                            <p>We've developed more than 40 startup applications and MVPs and many more startup websites.</p>
                        </div>
                        <div className="below-hero-widget">
                            <span className="widget-heading">2015</span>
                            <p>We started Launch Lab in 2015 to help non-technical Australian founders get started.</p>
                        </div>
                        <div className="below-hero-widget">
                            <span className="widget-heading">Local</span>
                            <p>We're an onshore startup web development team in Sydney and Canberra.</p>
                        </div>
                    </motion.div>
                    <div className="work-jumbo-wrapper mt-4 with-radius">
                        <motion.div 
                            className="work-jumbo-wrapper with-radius"
                            initial="hidden"
                            animate={{ opacity: [0, 0.25, 0.5, 0.75, 1], y: [120, 0], scale: [0.9, 1] }} 
                            transition={{ ease: "easeOut", delay: 0.5, duration: 1 }}
                        >
                            <StaticImage 
                                src="../images/launch-lab-work-2024.png"
                                alt="Screengrabs of some of the websites and applications that Launch Lab have designed and developed"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="none"
                                backgroundcolor="white"
                                layout="fullWidth"
                                quality="100%"
                                className="with-radius"
                            />
                        </motion.div>
                    </div>
                    <div className="flex flex-jc">
                        <Link to="/contact/" className="btn btn-lg btn-pink">
                        <span className="flex flex-ac">
                                Start your project
                            <ArrowRight />
                        </span>
                        </Link>
                    </div>
                </div>
            </div>
        </section>

        <section className="section-pad" id="anchor">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <motion.h3
                                initial={{ opacity: 0, y: 80 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ ease: "easeOut", duration: 0.5 }}
                            >
                                Trusted startup developers in Sydney &amp; Canberra
                            </motion.h3>
                        </div>

                        <motion.div 
                            className="col-67 long-text"
                            initial={{ opacity: 0, y: 80 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ ease: "easeOut", delay: 0.25, duration: 0.75 }}
                        >
                            <p>We're a <strong>local Australian software development team</strong> in Sydney and Canberra with a long list of happy startup clients.</p>
                            <p>We've developed startup applications for 2-sided marketplaces, market research platforms, Saas (Software-as-a-service) startups, fintech, edtech and healthtech startups.</p>
                            <p>We're trusted by entrepreneurs who are bootstrapping their own startups through to venture capital backed startups and acquired startups.</p>
                            <p>We have <strong>2 major differences</strong> which separate us from other software development teams and digital / web development agencies:</p>
                            <ol>
                                <li>All of our Python and React work is done in Australia. We do NOT offshore our coding work.</li>
                                <li>We've worked with more than 40 startups making us one of the most experienced startup developers in Australia.</li>
                            </ol> 
                            <p>If you're looking for a trusted local web development agency to help turn your business idea into a production ready product we'd love to chat.</p>                       
                            <div className="btn-row">
                                <Link to="/contact/" className="btn btn-lg btn-purple">
                                    <span className="flex flex-ac">
                                        Contact us
                                        <ArrowRight />
                                    </span>
                                </Link>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>
        </section>

        <div className="container">
          <div className="row">
            <div className="section-line"></div>
          </div>
        </div>

        <section className="section-pad">
        <div className="container">
          <div className="row">
            <div className="cols">
              <div className="col-26"
              >
                <h4>Partners</h4>
                <p>We're trusted by fintech, proptech, healthtech, marketplace and Saas early stage startups as well as funded startups, large organisations and government.</p>
              </div>
              <motion.div 
                className="col-67 flex flex-sb flex-wrap"
                initial={{ opacity: 0, y: 100 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ ease: "easeOut", delay: 0.25, duration: 0.75 }}
              >
                <ClientLogos />
              </motion.div>
            </div>
          </div>
        </div>
      </section>

        <div className="container">
            <div className="row">
                <div className="well bg-purple">
                    <h4>AWS &amp; Webflow for startups partner</h4>
                    <p>Launch Lab is a Webflow for Startups partner and an AWS Activate partner. Contact us to find out how you can benefit from these startup programs.</p>
                </div>
            </div>
        </div>

        <section className="section-pad" id="anchor">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <motion.h4
                                initial={{ opacity: 0, y: 80 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                                transition={{ ease: "easeOut", duration: 0.5 }}
                            >
                                Startup services
                            </motion.h4>
                        </div>

                        <motion.div 
                            className="col-67 long-text"
                            initial={{ opacity: 0, y: 80 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ ease: "easeOut", delay: 0.25, duration: 0.75 }}
                        >
                            <p>The technologies we use to develop software products for startups include: 
                            </p>
                            <ul>
                                <li>Python</li>
                                <li>React</li>
                                <li>AWS</li>    
                            </ul>
                            <p>If you're unsure what these technologies are, that's ok. We offer a 'geek speak' free approach but will share the knowledge you need to launch your online business.</p>
                            <p>If you're working within the usual startup constraints of time and money we can also help determine what to build.</p>
                            <p>Our services include:</p>
                            <ul className="black-lt">
                                <li>MVP (minimum viable product)</li>
                                <li>Web Design</li>
                                <li>Backend Development</li>
                                <li>Frontend Development</li>
                                <li>AWS Hosting &amp; Infrastructure</li>
                                <li>Startup Consulting &amp; Strategy</li>
                            </ul>
                            <div className="btn-row">
                                <Link to="/contact/" className="btn btn-lg btn-purple">
                                    <span className="flex flex-ac">
                                        Start your project
                                        <ArrowRight />
                                    </span>
                                </Link>
                            </div>
                        </motion.div>
                    </div>
                </div>
            </div>
        </section>

        <div className="container">
            <div className="row">
            <div className="section-line"></div>
            </div>
        </div>

        <section className="section-pad-sm">
            <div className="container">
                <div className="row">   
                    <h4 className="text-center">Startup projects</h4>
                    <motion.div 
                        className="porti-row mt-3"
                        initial={{ opacity: 0, y: 120 }}
                        whileInView={{ opacity: [0, 0.25, 0.5, 0.75, 1], y: 0, scale: [0.9, 1] }}
                        viewport={{ once: true }}
                        transition={{ ease: "easeOut", delay: "0.25", duration: 0.5 }}
                    >
                        <Link to="/case-study-giraffe-webflow-website/" className="porti-item">
                            <div className="porti-item-img bg-noise giraffe">
                                <StaticImage 
                                    src="../images/giraffe.png"
                                    alt="A collage of the Giraffe website screens"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h4>Giraffe</h4>
                                <p>We designed and developed a new Webflow website for this Sydney startup.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill webapp">Webflow</div>
                                    <div className="work-pill cms">CMS</div>
                                </div>
                            </div>
                        </Link>

                        <Link to="/feezy-case-study/" className="porti-item">
                            <div className="porti-item-img">
                                <StaticImage 
                                    src="../images/feezy-website.png"
                                    alt="Feezy website home page"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h4>Feezy</h4>
                                <p>We designed and developed a 2-sided marketplace web application for this Sydney, Australia, based startup.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill webapp">Web App</div>
                                    <div className="work-pill startup">Startup</div>
                                </div>
                            </div>
                        </Link>
                        <Link to="/enabli-case-study/" className="porti-item">
                            <div className="porti-item-img">
                                <StaticImage 
                                    src="../images/enabli-screens.png"
                                    alt="A collage of the Enabli website and application screens"
                                    formats={["AUTO", "WEBP", "AVIF"]}
                                    placeholder="none"
                                    backgroundcolor="white"
                                    layout="fullWidth"
                                    quality="100%"
                                />
                            </div>
                            <div className="porti-item-text">
                                <h4>Enabli</h4>
                                <p>We designed and developed HR Tech software and a marketing website for this Sydney-based startup.</p>
                                <div className="flex flex-wrap">
                                    <div className="work-pill website">Website</div>
                                    <div className="work-pill webapp">Web App</div>
                                    <div className="work-pill startup">Startup</div>
                                </div>
                            </div>
                        </Link>

                        <Link to="/startup-case-study-fintech/" className="porti-item">
                        <div className="porti-item-img">
                            <StaticImage 
                                src="../images/deferit-image.png"
                                alt="Image of lady smiling and other design assets from Deferit, a fintech startup in Australia"
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="none"
                                backgroundcolor="white"
                                layout="fullWidth"
                                quality="100%"
                            />
                        </div>
                        <div className="porti-item-text">
                            <h4>Deferit</h4>
                            <p>We developed a web and mobile app for Deferit, a fintech startup in Australia.</p>
                            <div className="flex flex-wrap">
                                <div className="work-pill webapp">Web App</div>
                                <div className="work-pill mobileapp">Mobile App</div>
                                <div className="work-pill startup">Startup</div>
                            </div>
                        </div>
                    </Link>
                    </motion.div>
                </div>
            </div>
        </section>

        <TestimonialSection />

        <section className="section-pad bg-black">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h5 className="title">
                                FAQs
                            </h5>
                        </div>
                        <div className="col-67" itemscope itemtype="https://schema.org/FAQPage">
                            <div itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                                <Collapsible 
                                    transitionTime={100}
                                    trigger={
                                    <>
                                        <div itemprop="name">What does it cost to develop a startup?</div> <ChevronDown />
                                    </>
                                    }
                                >   
                                    <div itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                        <div itemprop="text">
                                            <p>With budget usually being the biggest constraint for first time founders this is our most asked question.</p>
                                            <p>We've written extensively about this on our blog. The quick answer is that you could get started with a lean MVP for under ten thousand dollars. However, to develop a web application, pricing usually starts at $35,000.</p>
                                            <p>Keep in mind that our approach is lean and we'll help you determine the most cost effective method of getting to market.</p>
                                        </div>
                                    </div>
                                </Collapsible>
                            </div>
                            <div itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                                <Collapsible
                                    transitionTime={100}
                                    trigger={
                                    <>
                                        <div itemprop="name">What technologies do you use?</div> <ChevronDown />
                                    </>
                                    }
                                >
                                    <div itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                    <div itemprop="text">
                                    <p>When developing startups and web applications the majority of the work we do makes use of Python, AWS (serverless), GraphQL and React.js.</p>
                                    <p>We've also completed frontend projects using Vue.js and we're highly experienced Django developers too.</p>
                                    <p>For marketing websites and CMS projects our preference is Gatsby and Contentful but we'll happily use Webflow if it makes sense for your project.</p>
                                    </div>
                                    </div>
                                </Collapsible>
                            </div>
                            <div itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                                <Collapsible 
                                    transitionTime={100}
                                    trigger={
                                    <>
                                        <div itemprop="name">How long does is take to develop a startup product?</div> <ChevronDown />
                                    </>
                                    }
                                >
                                    <div itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                    <div itemprop="text">
                                    <p>Similar to the cost question above, the project timeline depends on the scope. Some MVPs are simply landing pages on a marketing website meaning you can be up and running in days. For full application development we find the sweet spot for a minimum viable product is 12 - 16 weeks.</p>
                                    </div>
                                    </div>
                                </Collapsible>
                            </div>
                            <div itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                                <Collapsible 
                                    transitionTime={100}
                                    trigger={
                                    <>
                                        <div itemprop="name">Does my startup have to be in Sydney or Canberra?</div> <ChevronDown />
                                    </>
                                    }
                                >
                                    <div itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                    <div itemprop="text">
                                    <p>No. While we're based in Sydney, and our CTO is based in Canberra, we've worked with startups throughout Australia including regional NSW, Brisbane, Melbourne, Perth and the Northern Territory before.
                                    </p>
                                    </div>
                                    </div>
                                </Collapsible>
                            </div>
                            <div itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                                <Collapsible 
                                    transitionTime={100}
                                    trigger={
                                    <>
                                        <div itemprop="name">I'm not a technical founder, is that ok?</div> <ChevronDown />
                                    </>
                                    }
                                >
                                    <div itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                    <div itemprop="text">
                                    <p>Yes. That's why Launch Lab exists. We'll help guide the technical aspects of your startup so that you can focus on growth.</p>
                                    </div>
                                    </div>
                                </Collapsible>
                            </div>
                            <div itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                                <Collapsible 
                                    transitionTime={100}
                                    trigger={
                                    <>
                                        <div itemprop="name">I am a technical founder, is that ok?</div> <ChevronDown />
                                    </>
                                    }
                                >
                                    <div itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                    <div itemprop="text">
                                    <p>Yes. We've worked with startups that have their own developers but either lack certain development skills or just need to expand their dev team to move faster.</p>
                                    </div>
                                    </div>
                                </Collapsible>
                            </div>
                            <div itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                                <Collapsible 
                                    transitionTime={100}
                                    trigger={
                                    <>
                                        <div itemprop="name">Do you offer ongoing support?</div> <ChevronDown />
                                    </>
                                    }
                                >
                                    <div itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                    <div itemprop="text">
                                    <p>Yes. We expect that we'll be your outsourced software engineers and web designers through the early stages of your startup.</p>
                                    <p>As you start to grow you'll hire your own employees to replace us. That's a great outcome for us and we'll be with you every step of the way.</p>
                                    </div>
                                    </div>
                                </Collapsible>
                            </div>
                            <div itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                                <Collapsible 
                                    transitionTime={100}
                                    trigger={
                                    <>
                                        <div itemprop="name">Do you take equity?</div> <ChevronDown />
                                    </>
                                    }
                                >
                                    <div itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                    <div itemprop="text">
                                    <p>If we have a good understanding of the market you'll be targeting and we've experienced the problem that you want to solve then there is a very good chance that we'd want to take equity. It's your choice and we're always open to discussing equity or fee based projects.</p>
                                    </div>
                                    </div>
                                </Collapsible>
                            </div>
                            <div itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                                <Collapsible 
                                    transitionTime={100}
                                    trigger={
                                    <>
                                        <div itemprop="name">Do you offer any other services?</div> <ChevronDown />
                                    </>
                                    }
                                >
                                    <div itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                    <div itemprop="text">
                                    <p>First and foremost we are product designers and application developers. That means we help plan, design and build startups. We also have the following experience:</p>
                                    <ul>
                                        <li>Digital marketing</li>
                                        <li>Copywriting</li>
                                        <li>Business strategy</li>
                                        <li>Data science and Machine Learning</li>
                                    </ul>
                                    </div>
                                    </div>
                                </Collapsible>
                            </div>
                            <div itemscope itemprop="mainEntity" itemtype="https://schema.org/Question">
                                <Collapsible 
                                    transitionTime={100}
                                    trigger={
                                    <>
                                        <div itemprop="name">Can you introduce us to venture capital firms and investors?</div> <ChevronDown />
                                    </>
                                    }
                                >
                                    <div itemscope itemprop="acceptedAnswer" itemtype="https://schema.org/Answer">
                                    <div itemprop="text">
                                    <p>We don't offer this as a service and we certainly don't charge for it. However, we'll help where we can and will make introductions if the time is right and the investor is highly relevant to the industry you are working in.</p>
                                    <p>The main thing to consider is that your goal at an early stage is not fund raising. Focus on getting to market and getting customers. You can focus on fund raising thereafter.</p>
                                    </div>
                                    </div>
                                </Collapsible>
                            </div>
                        </div>
                    </div>
 
                </div>
            </div>
        </section>

        <Cta 
            title="Startup development quotes"
            text="Get a startup web design quote and / or a startup web development quote from a local onshore Australian product development team"
        />
    </Layout>
)

export default StartupWebDevPage